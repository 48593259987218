import styled from '@grebban/style-system-react';

import Background from '@sportson/core-web/components/Background';
import { above } from '@sportson/core-web/utils/mediaqueries';

import { type BackgroundColorProps } from '@sportson/core-web/components/BackgroundColor';
import { type BackgroundImageProps } from '@sportson/core-web/components/BackgroundImage';
import { type BackgroundVideoProps } from '@sportson/core-web/components/BackgroundVideo';
import { type ButtonProps } from 'components/Button';
import { type CssColorKey } from 'config/branding/colors';
import { type CssVariable } from 'config/branding/types';
import { type WordpressACFWysiwyg } from 'libs/wordpress/types/acf/fields';

import Wysiwyg from 'libs/wordpress/components/Wysiwyg';
import Button from 'components/Button';
import Text from 'components/Text';

const Div = styled('div')``;

const ContentWrapper = styled('div')`
    color: var(--color-base-white);
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
`;

const BackgroundWrapper = styled('div')`
    position: absolute;
    display: flex;
    inset: 0;
    mix-blend-mode: multiply;
    padding: inherit;
    z-index: var(--zindex-background);
`;
const StyledBackground = styled(Background)`
    position: relative;
    flex: 1;
    border-radius: 4px;
    overflow: hidden;
`;

const TextContentContainer = styled('div')`
    width: 100%;
    height: 100%;
    display: flex;
`;

const TextContentWrapper = styled('div')`
    height: fit-content;
    width: 100%;
    display: grid;
    justify-items: flex-start;
    align-items: flex-end;
    gap: 12px;
    background-color: ${({ displayTextContentBackground }) =>
        displayTextContentBackground ? 'rgba(0, 0, 0, 0.5)' : 'transparent'};
    backdrop-filter: ${({ displayTextContentBackground }) => (displayTextContentBackground ? 'blur(6px)' : 'unset')};
    border-radius: 4px;

    ${above['tablet.sm']} {
        gap: 16px;
        width: fit-content;
        max-width: 600px;
    }
`;
export interface CallToActionComponentPropsHeading {
    text: WordpressACFWysiwyg;
    type?: 'default' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    color?: CssVariable<CssColorKey>;
}

export interface CallToActionComponentPropsParagraph {
    text: WordpressACFWysiwyg;
    color?: CssVariable<CssColorKey>;
}

export type CallToActionComponentAlignmentProps = 'flex-start' | 'center' | 'flex-end';
export interface CallToActionComponentProps extends Record<string, unknown> {
    backgroundColor?: BackgroundColorProps;
    backgroundImage?: BackgroundImageProps;
    backgroundVideo?: BackgroundVideoProps;
    heading?: CallToActionComponentPropsHeading;
    buttons?: ButtonProps[];
    paragraph?: CallToActionComponentPropsParagraph;
    tag?: string;
    contentHorizontalAlignment?: CallToActionComponentAlignmentProps;
    contentVerticalAlignment?: CallToActionComponentAlignmentProps;
    enableTextContentBackground?: boolean;
    contentTextColor?: CssVariable<CssColorKey>;
    textContentPadding?: (string | null)[];
    columnsRatio?: (string | null)[] | null;
}

const horizontalAlignmentToTextAlignment: Record<CallToActionComponentAlignmentProps, string> = {
    'flex-start': 'left',
    center: 'center',
    'flex-end': 'right',
};

const CallToActionComponent = ({
    backgroundColor,
    backgroundImage,
    backgroundVideo,
    heading,
    buttons,
    paragraph,
    tag,
    contentVerticalAlignment = 'flex-end',
    contentHorizontalAlignment = 'flex-start',
    enableTextContentBackground = true,
    contentTextColor,
    textContentPadding,
    columnsRatio,
    ...rest
}: CallToActionComponentProps) => {
    const textAlignment = horizontalAlignmentToTextAlignment[contentHorizontalAlignment];

    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- Reason: the Link uses the style package, but it is not correctly ts yet.
        // @ts-ignore
        <ContentWrapper aspectRatio={columnsRatio}>
            <BackgroundWrapper>
                <StyledBackground
                    backgroundVideo={backgroundVideo}
                    backgroundImage={backgroundImage}
                    backgroundColor={backgroundColor}
                />
            </BackgroundWrapper>

            <TextContentContainer
                justifyContent={contentHorizontalAlignment}
                alignItems={contentVerticalAlignment}
                p={[null, null, '16px']}
            >
                <TextContentWrapper
                    alignItems={textAlignment}
                    displayTextContentBackground={enableTextContentBackground}
                    {...rest}
                >
                    {tag && (
                        <Text
                            $as="p"
                            color={contentTextColor || 'var(--cta-module-text-color-default)'}
                            typography="UI/12_100_0_450_uppercase"
                        >
                            {tag}
                        </Text>
                    )}

                    {/* TODO: Add logic for heading size */}

                    {heading && (
                        <Wysiwyg
                            data={heading.text}
                            TextComponent={(props) => (
                                <Text
                                    textAlign="left"
                                    as={heading.type || 'h3'}
                                    color={heading.color || contentTextColor || 'var(--cta-module-text-color-default)'}
                                    typography={[
                                        'Content/24_130_0_450',
                                        null,
                                        null,
                                        null,
                                        null,
                                        'Content/32_120_0_500',
                                    ]}
                                    {...props}
                                />
                            )}
                        />
                    )}
                    {paragraph && (
                        <Wysiwyg
                            data={paragraph.text}
                            TextComponent={(props) => (
                                <Text
                                    textAlign="left"
                                    $as="p"
                                    color={
                                        paragraph.color || contentTextColor || 'var(--cta-module-text-color-default)'
                                    }
                                    typography="Content/16_150_0_350"
                                    {...props}
                                />
                            )}
                        />
                    )}

                    {buttons && (
                        <Div display="flex" flexDirection="row" justifyContent="flex-start" gap="16px">
                            {buttons.map((button) => (
                                <Button
                                    key={`${button.url}`}
                                    typography="UI/16_100_0_450"
                                    theme={button.theme || 'primary'}
                                    to={button.url}
                                >
                                    {button.children}
                                </Button>
                            ))}
                        </Div>
                    )}
                </TextContentWrapper>
            </TextContentContainer>
        </ContentWrapper>
    );
};

export default CallToActionComponent;
