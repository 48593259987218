import React from 'react';
import useBreakpoint from '@sportson/core-web/hooks/useBreakpoint';
import styled from '@grebban/style-system-react';

import { type BackgroundColorProps } from '@sportson/core-web/components/BackgroundColor';
import { type BackgroundImageProps } from '@sportson/core-web/components/BackgroundImage';
import { type BackgroundVideoProps } from '@sportson/core-web/components/BackgroundVideo';
import CallToActionComponent, {
    type CallToActionComponentAlignmentProps,
    type CallToActionComponentProps,
} from 'content/CallToAction';

import { type WordpressTag } from 'libs/wordpress/types/contentSystem/elements';
import {
    type WordpressBackgroundSelection,
    type WordpressFlexibleNavigation,
    type WordpressHeadingGroup,
    type WordpressParagraphGroup,
} from 'libs/wordpress/types/contentSystem/groups';
import {
    type WordpressHorizontalAlignment,
    type WordpressTextColor,
    type WordpressVerticalAlignment,
} from 'libs/wordpress/types/contentSystem/settings';
import { transformBackgroundGroup } from 'libs/wordpress/utils/transformBackground';
import { transformLinkButton } from 'libs/wordpress/utils/transformButton';
import { transformHeadingGroup } from 'libs/wordpress/utils/transformHeading';
import { transformParagraphGroup } from 'libs/wordpress/utils/transformParagraph';
import transformTextColor from 'libs/wordpress/utils/transformTextColor';
import { ArrowIcon } from 'assets/media/icons/ArrowIcon';

const Div = styled('div')`
    height: 100%;
`;
export interface WordpressCallToActionBlock {
    settings: Array<'tag' | 'heading' | 'paragraph' | 'link'>;
    textColor: WordpressTextColor;
    contentHorizontalAlignment: WordpressHorizontalAlignment;
    contentVerticalAlignment: WordpressVerticalAlignment;
    background: WordpressBackgroundSelection;
    tag?: WordpressTag;
    heading?: WordpressHeadingGroup;
    paragraph?: WordpressParagraphGroup;
    link?: WordpressFlexibleNavigation;
    textContentBackgroundBlur?: boolean;
}

// TODO: Remove Record<string, unknown> when styling is fixed, correctly pass css via rest
export interface CallToActionBlockProps extends Record<string, unknown> {
    data: WordpressCallToActionBlock;
    isHero?: boolean;
    layout?: 'callToAction' | 'twoColumns' | 'threeColumns';
}

const hAlignmentMapper: Record<WordpressHorizontalAlignment, CallToActionComponentAlignmentProps | undefined> = {
    default: undefined,
    right: 'flex-end',
    center: 'center',
    left: 'flex-start',
};
const vAlignmentMapper: Record<WordpressVerticalAlignment, CallToActionComponentAlignmentProps | undefined> = {
    default: undefined,
    bottom: 'flex-end',
    middle: 'center',
    top: 'flex-start',
};

const CallToActionBlock: React.FunctionComponent<CallToActionBlockProps> = ({ data, layout, ...rest }) => {
    const componentData: CallToActionComponentProps = {
        contentHorizontalAlignment: hAlignmentMapper[data?.contentHorizontalAlignment],
        contentVerticalAlignment: vAlignmentMapper[data?.contentVerticalAlignment],
        enableTextContentBackground: data.textContentBackgroundBlur,
    };

    const isMobile = useBreakpoint({ to: 'tablet.sm' });
    const device = isMobile ? 'mobile' : 'desktop';
    const isUsedInColumn = layout === 'twoColumns' || layout === 'threeColumns';

    // Background
    if (data.background.desktop) {
        const { type: backgroundType, data: backgroundData } = transformBackgroundGroup(data.background, device);
        if (backgroundType === 'color') {
            componentData.backgroundColor = backgroundData as BackgroundColorProps;
        } else if (backgroundType === 'image') {
            componentData.backgroundImage = backgroundData as BackgroundImageProps;
        } else if (backgroundType === 'video') {
            componentData.backgroundVideo = backgroundData as BackgroundVideoProps;
        }
    }

    // Background
    if (data.background.mobile) {
        const { type: backgroundType, data: backgroundData } = transformBackgroundGroup(data.background, device);
        if (backgroundType === 'color') {
            componentData.backgroundColor = backgroundData as BackgroundColorProps;
        } else if (backgroundType === 'image') {
            componentData.backgroundImage = backgroundData as BackgroundImageProps;
        } else if (backgroundType === 'video') {
            componentData.backgroundVideo = backgroundData as BackgroundVideoProps;
        }
    }

    // Heading
    if (data.settings.includes('heading') && data.heading) {
        componentData.heading = transformHeadingGroup(data.heading, device);
    }

    // Link
    if (data.settings.includes('link') && data.link) {
        const linkType = data.link[0].layout;
        if (linkType === 'buttons') {
            componentData.buttons = data.link[0].buttons.map((button) => {
                const tButton = transformLinkButton(button.button);
                return {
                    url: tButton.link,
                    children: (
                        <>
                            {tButton.label}
                            <ArrowIcon />
                        </>
                    ),
                    theme: tButton.theme,
                };
            });
        } else if (linkType === 'content') {
            componentData.contentLink = data.link[0].link;
        }
    }

    // Tag
    if (data.settings.includes('tag') && data.tag) {
        componentData.tag = data.tag;
    }

    // Text
    if (data.settings.includes('paragraph') && data.paragraph) {
        componentData.paragraph = transformParagraphGroup(data.paragraph, device);
    }

    // Text Color
    if (data.textColor) {
        componentData.contentTextColor = transformTextColor(data.textColor);
    }

    return (
        <Div {...rest}>
            <CallToActionComponent
                columnsRatio={
                    layout === 'threeColumns'
                        ? ['2/3', null, null, null, '2/3']
                        : layout === 'twoColumns'
                          ? ['2/3', null, null, null, '1/1']
                          : null
                }
                minWidth={isUsedInColumn ? '100%' : 'auto'}
                p={isUsedInColumn ? ['16px', null, null, null, '24px'] : ['16px', null, null, null, '32px']}
                {...componentData}
            />
        </Div>
    );
};

export default CallToActionBlock;
