import React from 'react';
import FullWidth from '@sportson/core-web/App/layouts/FullWidth';
import CallToActionBlock, { type WordpressCallToActionBlock } from 'libs/wordpress/content/Blocks/CallToAction';
import { type WordpressStandardModuleSettings } from '../../types/contentSystem/groups';
import { transformBackgroundColor } from '../../utils/transformBackground';

export interface CallToActionModuleProps extends Record<string, unknown> {
    data: {
        data: WordpressCallToActionBlock;
        moduleSettings: WordpressStandardModuleSettings;
    };
    isHero?: boolean;
}

const CallToActionModule: React.FC<CallToActionModuleProps> = ({ data, isHero, ...rest }) => {
    const { moduleSettings, data: blockData } = data;
    const { backgroundColor } = moduleSettings;

    // @TODO Implement background, spacing, ratio and visibility
    const moduleBgColor = transformBackgroundColor(backgroundColor).backgroundColor;
    return (
        <FullWidth
            height={['100svh', null, '100vh']}
            gridColumn={isHero ? 'full-width' : ''}
            mt={isHero ? '' : 'var(--module-margin-top-default)'}
            mb={isHero ? 'var(--module-margin-bottom-small)' : 'var(--module-margin-bottom-default)'}
            backgroundColor={moduleBgColor || 'var(--color-transparent)'}
            p={['40px 12px', null, '80px 16px 42px', null, '40px 0']}
        >
            <CallToActionBlock {...rest} data={blockData} layout="callToAction" />
        </FullWidth>
    );
};
export default CallToActionModule;
